header{
    padding: 15px 0px;
}
.search-style{
    margin: 20px 0px;
}
.search-bar{
    background: #fff;
    transition: all 300ms ease;
}
.search-bar.ashadow{
    box-shadow: 0 15px 5px -15px rgba(0,0,0,0.2);
}
.table-hover tbody tr:first-child:hover{
    background: transparent;
}
.loader{
    margin: 20px;
    text-align: justify;
    color:#999;
}
.footer-info{
    margin: 15px 0px;
}
.spinner {
    margin: 20px auto 0;
    width: 70px;
    text-align: center;
}
.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #80bdff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

@media screen and (max-width: 640px){
    .search-style{
        margin: 10px 0px;
    }
    .hide-column{
        display: none;
    }
    table.table{
        font-size: 13px;
    }
}
